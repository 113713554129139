<template>
    <div class="page-box">
        <v-header></v-header>
        <div class="f-col-c-c h100">
            <div class="f-row-c-c wrap" style="margin-bottom: 200px;">
                <img style="width:931px;" src="../../assets/images/index_word.png" alt="">
            </div>

            <div class="form-box wrap">
                <!-- tabs区 -->
                <div class="form-tabs f-row-s-e">
                    <div class="tabs f-row-c-c" :class="{ 'active': curTabs == 0 }" @click="changeTabs(0)">
                        <div class="one-txt-cut">{{ $t('N30') }}</div>
                    </div>
                    <div class="tabs f-row-c-c" :class="{ 'active': curTabs == 1 }" @click="changeTabs(1)">
                        <div class="one-txt-cut">{{ $t('N31') }}</div>
                    </div>
                </div>

                <!-- 表单区 -->
                <div class="form-con">
                    <div class="f-row-s-c f-w" style="column-gap:30px;">
                        <div class="card-box">
                            <div class="card-tit f-row-s-c">
                                <img src="../../assets/images/index_icon_place.png" alt="">
                                {{ $t('N1') }}
                            </div>
                            <div class="address-box">
                                <v-address width="328px" height="50px" @inputValue="inputValueStart"
                                    :placeholder="$t('N1') + $t('I26')"></v-address>
                            </div>
                        </div>
                        <div v-if="curTabs == 0" class="card-box">
                            <div class="card-tit f-row-s-c">
                                <img src="../../assets/images/index_icon_place.png" alt="">
                                {{ $t('N2') }}
                            </div>
                            <div class="address-box">
                                <v-address width="328px" height="50px" @inputValue="inputValueEnd"
                                    :placeholder="$t('N2') + $t('I26')"></v-address>
                            </div>
                        </div>
                        <div class="card-box">
                            <div class="card-tit f-row-s-c">
                                <img src="../../assets/images/index_icon_renshu.png" alt="">
                                {{ $t('M34') }}
                            </div>
                            <div class="inputs">
                                <el-input v-model="form.carPerson" :placeholder="$t('home.fare')" clearable></el-input>
                            </div>
                        </div>
                        <div v-if="curTabs == 1" class="card-box">
                            <div class="card-tit f-row-s-c">
                                <img src="../../assets/images/index_icon_time.png" alt="">
                                {{ $t('home.duration') }}
                            </div>
                            <div class="car-box inputs">
                                <el-select class="w100" v-model="form.carTimeId" :placeholder="$t('home.rent')"
                                    @change="changeCarTime">
                                    <el-option v-for="item in carTimeList" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="card-box">
                            <div class="card-tit f-row-s-c">
                                <img src="../../assets/images/index_icon_date.png" alt="">
                                {{ $t('N3') }}
                            </div>
                            <div class="time-box inputs f-row-b-c">
                                <el-date-picker type="date" v-model="form.date" prefix-icon="0"
                                    :placeholder="$t('home.date')" value-format="yyyy-MM-dd" clearable>
                                </el-date-picker>
                                <i class="el-icon-arrow-down"></i>
                            </div>
                        </div>
                        <div class="card-box">
                            <div class="card-tit f-row-s-c">
                                <img src="../../assets/images/index_icon_time.png" alt="">
                                {{ $t('N4') }}
                            </div>
                            <div class="time-box inputs f-row-b-c">
                                <el-time-picker v-model="form.time" prefix-icon="0" :placeholder="$t('home.time')"
                                    value-format="HH:mm" format="HH:mm" clearable>
                                </el-time-picker>
                                <i class="el-icon-arrow-down"></i>
                            </div>
                        </div>
                    </div>
                    <!-- 操作区 -->
                    <div class="operate-box f-row-c-c">
                        <button class="btn" @click="goCalculate">{{ $t('M78') }}</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    data() {
        return {
            location: null,//获取当前位置信息
            curTabs: 0,//tabs

            form: {
                startAddress: '',//出发地
                endAddress: '',//到达地
                carPerson: '',//乘客数
                date: '', //出发日期
                time: '', //出发时间
                carTimeId: '',//用车时长id
            },

            carTimeList: [],//用车时长列表
            carTimeInfo: {},//选中的用车时长
        }
    },
    created() {
        this.getCurrentLocation();
        this.getCarTimeList()
    },
    methods: {
        //请求是否允许获取当前位置信息
        getCurrentLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        this.location = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        };
                    },
                    error => {
                        this.error = error.message;
                    }
                );
            } else {
                this.error = "Geolocation is not supported by this browser.";
            }
        },
        //获取用车时长列表
        getCarTimeList() {
            $api.listCharterTime().then(res => {
                if (res.code == 200) {
                    let querys = res.data || []
                    this.carTimeList = []
                    if (querys && querys.length > 0) {
                        querys.forEach(i => {
                            this.carTimeList.push({
                                label: i.rentalDuration + 'H',
                                value: i.rentalDuration,
                            })
                        })
                    }
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //切换formtabs
        changeTabs(i) {
            this.curTabs = i
            if (i == 0) {
                this.orderDialogType = '4'
            } else if (i == 1) {
                this.orderDialogType = '6'
            }
        },
        //赋值出发地地址
        inputValueStart(e) {
            this.form.startAddress = e;
        },
        //赋值结束地地址
        inputValueEnd(e) {
            this.form.endAddress = e;
        },
        //选择用车时长
        changeCarTime(e) {
            this.carTimeInfo = this.carTimeList.find((item) => item.value == e)
        },
        //计算价格
        goCalculate() {
            if (!this.form.startAddress) {
                this.$message.error(this.$t('M44'))
                return
            }
            if (!this.form.carPerson) {
                this.$message.error(this.$t('M44'))
                return
            }
            if (!this.form.date) {
                this.$message.error(this.$t('M44'))
                return
            }
            if (!this.form.time) {
                this.$message.error(this.$t('M44'))
                return
            }

            let dataParams = {}
            let params = {}
            dataParams = {
                setOutAddress: this.form.startAddress,
                numberOfPassengers: this.form.carPerson,
                appointmentTime: this.form.date + ' ' + this.form.time + ':00',
            }
            if (this.curTabs == 0) {
                //专车服务
                if (!this.form.endAddress) {
                    this.$message.error(this.$t('M44'))
                    return
                }
                const loading = this.$loading('loading-box', this.$t('info34'), true, 'el-icon-loading', 'rgba(0, 0, 0, 0.8)')
                //计算距离
                $api.distanceCalculation({
                    originsAddress: this.form.startAddress,
                    destinationsAddress: this.form.endAddress,
                }).then(res => {
                    loading.close()
                    if (res.code == 200) {
                        this.$message.success(this.$t('info35'))
                        let datas = res.data || {}
                        params = {
                            arriveOutAddress: this.form.endAddress,
                            travelDistance: datas.kilometers || '0',
                            toll: datas.toll || '0',
                        }
                        Object.assign(dataParams, params)
                        localStorage.setItem('orderInfo', encodeURIComponent(JSON.stringify(dataParams)))
                        this.$router.push({
                            path: '/carList', query: { type: 0 }
                        })
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            } else if (this.curTabs == 1) {
                //包车服务
                if (Object.keys(this.carTimeInfo).length == 0) {
                    this.$message.error(this.$t('M44'))
                    return
                }
                params = {
                    vehicleDuration: this.form.carTimeId,
                }
                Object.assign(dataParams, params)
                localStorage.setItem('orderInfo', encodeURIComponent(JSON.stringify(dataParams)))
                this.$router.push({
                    path: '/carList', query: { type: 1 }
                })
            }
        },
    }
}
</script>


<style lang="scss" scoped>
.page-box {
    width: 100vw;
    height: 100vh;
    background: url('../../assets/images/index_bg.png') no-repeat center center/cover;
    background-attachment: fixed;
    overflow-y: auto;
}

.form-box {
    position: relative;

    ::v-deep .el-input__inner {
        padding: 0 !important;
        border: none !important;
    }
}

.form-tabs {
    position: absolute;
    left: 0;
    top: -75px;
    z-index: 1;

    .tabs {
        min-width: 256px;
        height: 68px;
        font-weight: bold;
        font-size: 28px;
        color: #333;
        background: #f1f1f1;
        border-radius: 5px 5px 0 0;
        cursor: pointer;

        &.active {
            height: 75px;
            color: #fff;
            background: var(--theme-color);
        }
    }
}

.form-con {
    padding: 0 76px;
    background: #fff;

    .card-tit {
        padding: 30px 0 12px;
        font-size: 18px;
        color: #333;

        img {
            margin-right: 8px;
            max-width: 18px;
            width: auto;
        }
    }

    .inputs {
        padding: 0 14px;
        width: 328px;
        height: 50px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        box-sizing: border-box;
    }
}

.operate-box {
    padding: 20px 0;

    .btn {
        width: 376px;
        height: 56px;
        line-height: 56px;
        font-size: 20px;
    }
}
</style>