<template>
    <div class="page-box">
        <ul class="ul-box">
            <li class="f-row-s-c" v-for="(item, index) in navList" :key="index"
                :class="{ 'active': $route.path == item.url }" @click="navBar(item)">
                <div class="img-box f-row-c-c">
                    <img class="imgs" :src="$route.path == item.url ? item.selectImg : item.img" alt="">
                </div>
                <span>{{ item.name }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            active: 1,
            navList: [{
                id: 1,
                name: this.$t('PH5'),
                url: '/centre/order',
                img: require('../assets/images/qyzx_icon_wddd.png'),
                selectImg: require('../assets/images/grzy_icon_wddd.png')
            }, {
                id: 2,
                name: this.$t('my4'),
                url: '/centre/firm',
                img: require('../assets/images/grzy_icon_qyzx.png'),
                selectImg: require('../assets/images/qyzx_icon_qyzx.png')
            }, {
                id: 3,
                name: this.$t('my5'),
                url: '/centre/promotion',
                img: require('../assets/images/grzy_icon_tfzx.png'),
                selectImg: require('../assets/images/icon_tg.png')
            }, {
                id: 4,
                name: this.$t('my6'),
                url: '/centre/finance',
                img: require('../assets/images/grzy_icon_zwzx.png'),
                selectImg: require('../assets/images/cwzx_icon_cwzx.png')
            }, {
                id: 5,
                name: this.$t('my8'),
                url: '/centre/set',
                img: require('../assets/images/grzy_icon_set.png'),
                selectImg: require('../assets/images/set_icon_set.png')
            }],
        }
    },
    mounted() {
        this.active = sessionStorage.getItem('ACTIVE_MENU_PERSON');
    },
    methods: {
        //导航
        navBar(item) {
            this.active = item.id;
            sessionStorage.setItem('ACTIVE_MENU_PERSON', item.id);
            this.$router.push(item.url, () => { }, () => { })
        },
    }
}
</script>

<style lang="scss" scoped>
.page-box {
    flex: 0 0 230px;
    margin-right: 15px;
    width: 230px;
}

.ul-box {
    padding: 14px 0;
    background: #fff;
    border-radius: 5px;

    li {
        padding-left: 32px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        color: #333;
        cursor: pointer;
        user-select: none;

        &.active {
            padding-left: 28px;
            color: var(--theme-color);
            background: #f2f3f5;
            border-left: 4px solid var(--theme-color);
        }

        .img-box {
            width: 20px;
            height: 20px;

            .imgs {
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
            }
        }

        span {
            margin-left: 24px;
        }
    }
}
</style>