var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top-box f-row-b-c"},[_c('div',{staticClass:"codes"},[_vm._v(_vm._s(_vm.$t('D6'))+"："+_vm._s(_vm.item.orderNo || ''))]),(_vm.item.orderType == 1)?_c('div',{staticClass:"types red"},[_vm._v(_vm._s(_vm.$t('home.zhuanche')))]):_vm._e(),(_vm.item.orderType == 2)?_c('div',{staticClass:"types"},[_vm._v(_vm._s(_vm.$t('home.baoche')))]):_vm._e()]),_c('div',{staticClass:"card-box"},[_c('div',{staticClass:"info-box"},[_c('div',{staticClass:"cards f-row-s-c"},[_vm._m(0),_c('span',[_vm._v(_vm._s(_vm.$t('M30'))+"：")]),_c('div',{staticClass:"ell1",staticStyle:{"width":"700px"}},[_vm._v(_vm._s(_vm.item.setOutAddress || ''))])]),(_vm.item.orderType == 1)?_c('div',{staticClass:"cards f-row-s-c u-line-1"},[_vm._m(1),_c('span',[_vm._v(_vm._s(_vm.$t('M47'))+"：")]),_c('div',{staticClass:"ell1",staticStyle:{"width":"700px"}},[_vm._v(_vm._s(_vm.item.arriveOutAddress || ''))])]):_vm._e(),(_vm.item.orderType == 2)?_c('div',{staticClass:"cards f-row-s-c"},[_vm._m(2),_c('span',[_vm._v(_vm._s(_vm.$t('M32'))+"：")]),_vm._v(" "+_vm._s(_vm.item.appointmentTime || '')+" ")]):_vm._e(),_c('div',{staticClass:"card-row f-row-s-c"},[(_vm.item.orderType == 1)?_c('div',{staticClass:"cards items f-row-s-c"},[_vm._m(3),_c('span',[_vm._v(_vm._s(_vm.$t('M32'))+"：")]),_vm._v(" "+_vm._s(_vm.item.appointmentTime || '')+" ")]):_vm._e(),(_vm.item.orderType == 2)?_c('div',{staticClass:"cards items f-row-s-c"},[_vm._m(4),_c('span',[_vm._v(_vm._s(_vm.$t('M35'))+"：")]),_vm._v(" "+_vm._s(_vm.item.vehicleDuration ? _vm.item.vehicleDuration + 'H' : '')+" ")]):_vm._e(),_c('div',{staticClass:"cards items f-row-s-c"},[_vm._m(5),_c('span',[_vm._v(_vm._s(_vm.$t('M34'))+"：")]),_vm._v(" "+_vm._s(_vm.item.numberOfPassengers || '0')+" ")])])]),_c('div',{staticClass:"btn-box"},[_vm._t("default")],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgs f-row-c-c"},[_c('img',{attrs:{"src":require("../assets/images/index_icon_place.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgs f-row-c-c"},[_c('img',{attrs:{"src":require("../assets/images/index_icon_place.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgs f-row-c-c"},[_c('img',{attrs:{"src":require("../assets/images/index_icon_date.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgs f-row-c-c"},[_c('img',{attrs:{"src":require("../assets/images/index_icon_date.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgs f-row-c-c"},[_c('img',{attrs:{"src":require("../assets/images/index_icon_time.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"imgs f-row-c-c"},[_c('img',{attrs:{"src":require("../assets/images/index_icon_renshu.png"),"alt":""}})])
}]

export { render, staticRenderFns }