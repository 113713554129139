<template>
    <div>
        <el-dialog :visible.sync="isShow" width="676px" :show-close="false" @close="closeDialog">
            <!-- 头部区 -->
            <div class="dialog-top f-row-b-c" slot="title">
                <span class="titles">{{ $t('N20') }}</span>
                <i class="closes el-icon-close" @click="closeDialog"></i>
            </div>
            <!-- 内容区 -->
            <div class="dialog-con f-row-c-c">
                {{ tipsData.title || '' }}
            </div>
            <!-- 底部区 -->
            <div class="dialog-foot f-row-c-c" slot="footer">
                <el-button type="primary" @click="handleSubmit">{{ $t('M24') }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import $api from '@/api/api.js'
export default {
    name: 'Vtips-dialog',
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        tipsData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {}
    },
    methods: {
        //关闭弹窗
        closeDialog() {
            this.$emit('closeDialog', false)
        },
        //确定
        handleSubmit() {
            $api[this.tipsData.api](this.tipsData.id).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg)
                    this.$emit('confirmDialog', false)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.dialog-con {
    height: 206px;
    font-size: 26px;
    color: #2E2D2D;
}
</style>