import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/home.vue'
import carList from '../views/home/carList.vue'
import confirm from '../views/home/confirm.vue'
import centre from '../views/mine/centre.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: home, meta: { title: '首页' } },
  { path: '/carList', component: carList, meta: { title: '车辆列表' } },
  { path: '/confirm', component: confirm, meta: { title: '确认订单' } },
  {
    path: '/centre', component: centre, meta: { title: '个人中心' },
    children: [
      { path: 'order', component: () => import('@/views/mine/menu/order.vue'), meta: { title: '我的订单' } },
      { path: 'firm', component: () => import('@/views/mine/menu/firm.vue'), meta: { title: '企业中心' } },
      { path: 'promotion', component: () => import('@/views/mine/menu/promotion.vue'), meta: { title: '推广中心' } },
      { path: 'finance', component: () => import('@/views/mine/menu/finance.vue'), meta: { title: '财务中心' } },
      { path: 'set', component: () => import('@/views/mine/menu/set.vue'), meta: { title: '设置' } },
      { path: 'link', component: () => import('@/views/mine/menu/link.vue'), meta: { title: '专属链接' } },
      { path: 'orderDetails', component: () => import('@/views/mine/menu/orderDetails.vue'), meta: { title: '订单详情' } },
    ] 
  },
]

const router = new VueRouter({
  routes
})

export default router
