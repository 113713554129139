import Vue from "vue";

import VHeader from "@/components/VHeader.vue";
import VSidebar from '@/components/VSidebar.vue'
import VOrder from '@/components/VOrder.vue'
import VTitle from '@/components/VTitle.vue'
import VtipsDialog from '@/components/VtipsDialog.vue'
import VAddress from "@/components/VAddress.vue";
import VCar from '@/components/VCar.vue'
import VpayDialog from '@/components/VpayDialog.vue'



Vue.component('VHeader', VHeader);
Vue.component('VSidebar', VSidebar);
Vue.component('VOrder', VOrder);
Vue.component('VTitle', VTitle);
Vue.component('VtipsDialog', VtipsDialog);
Vue.component('VAddress', VAddress);
Vue.component('VCar', VCar);
Vue.component('VpayDialog', VpayDialog);