<template>
    <div>
        <!-- 头部区 -->
        <div class="top-box f-row-b-c">
            <div class="codes">{{ $t('D6') }}：{{ item.orderNo || '' }}</div>
            <div v-if="item.orderType == 1" class="types red">{{ $t('home.zhuanche') }}</div>
            <div v-if="item.orderType == 2" class="types">{{ $t('home.baoche') }}</div>
        </div>

        <div class="card-box">
            <!-- 信息区 -->
            <div class="info-box">
                <div class="cards f-row-s-c">
                    <div class="imgs f-row-c-c">
                        <img src="../assets/images/index_icon_place.png" alt="">
                    </div>
                    <span>{{ $t('M30') }}：</span>
                    <div class="ell1" style="width:700px;">{{ item.setOutAddress || '' }}</div>
                </div>
                <div v-if="item.orderType == 1" class="cards f-row-s-c u-line-1">
                    <div class="imgs f-row-c-c">
                        <img src="../assets/images/index_icon_place.png" alt="">
                    </div>
                    <span>{{ $t('M47') }}：</span>
                    <div class="ell1" style="width:700px;">{{ item.arriveOutAddress || '' }}</div>
                </div>
                <div v-if="item.orderType == 2" class="cards f-row-s-c">
                    <div class="imgs f-row-c-c">
                        <img src="../assets/images/index_icon_date.png" alt="">
                    </div>
                    <span>{{ $t('M32') }}：</span>
                    {{ item.appointmentTime || '' }}
                </div>
                <div class="card-row f-row-s-c">
                    <div v-if="item.orderType == 1" class="cards items f-row-s-c">
                        <div class="imgs f-row-c-c">
                            <img src="../assets/images/index_icon_date.png" alt="">
                        </div>
                        <span>{{ $t('M32') }}：</span>
                        {{ item.appointmentTime || '' }}
                    </div>
                    <div v-if="item.orderType == 2" class="cards items f-row-s-c">
                        <div class="imgs f-row-c-c">
                            <img src="../assets/images/index_icon_time.png" alt="">
                        </div>
                        <span>{{ $t('M35') }}：</span>
                        {{ item.vehicleDuration ? item.vehicleDuration + 'H' : '' }}
                    </div>
                    <div class="cards items f-row-s-c">
                        <div class="imgs f-row-c-c">
                            <img src="../assets/images/index_icon_renshu.png" alt="">
                        </div>
                        <span>{{ $t('M34') }}：</span>
                        {{ item.numberOfPassengers || '0' }}
                    </div>
                </div>
            </div>
            <!-- 操作区 -->
            <div class="btn-box">
                <slot></slot>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'VOrder',
    props: {
        item: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {}
    },
}
</script>

<style lang="scss" scoped>
.ell1{
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical !important;
}
.top-box {
    padding: 0 12px;
    height: 50px;
    font-size: 14px;
    color: #333;
    background: #f2f3f4;
    border-bottom: 1px solid #e6e6e6;

    .types {
        min-width: 30px;
        height: 16px;
        line-height: 16px;
        font-size: 12px;
        color: #FFFFFF;
        text-align: center;
        background: #65E18F;

        &.red {
            background: #FF4200;
        }
    }
}

.card-box {
    position: relative;
    padding: 12px;
}

.cards {
    line-height: 40px;
    font-size: 16px;
    color: #2E2D2D;

    &.items {
        margin-right: 40px;
    }

    .imgs {
        flex: 0 0 26px;
        width: 26px;
        height: 16px;

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    span {
        color: #707070;
    }
}

.btn-box {
    position: absolute;
    right: 12px;
    bottom: 12px;
}
</style>