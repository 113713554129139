import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 从语言包文件中导入语言包对象
import en from './en.json'
import zh from './zh-Hans.json'
Vue.use(VueI18n)
const messages = {
        zh: {
                ...zh,
        },
        en: {
                ...en,
        }
}
const i18n = new VueI18n({
        messages,
        locale: "zh",
        fallbackLocale: 'zh', // 若某个语言环境变量，则使用fallbackLocale环境下对应的变量
        silentFallbackWarn: true, // 抑制警告
        globalInjection: true // 全局注入
})
export default i18n