import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //登录弹窗
    showLogin: false,
    //注册弹窗
    showRegister: false,
    //忘记密码弹窗
    showPass: false,
    //提交资料弹窗
    showDatum: false
  },
  getters: {
  },
  mutations: {
    SHOWLOGIN(state) {
      state.showLogin = true
    },
    NOSHOWLOGIN(state) {
      state.showLogin = false
    },
    SHOWREGISTER(state) {
      state.showRegister = true
    },
    NOSHOWREGISTER(state) {
      state.showRegister = false
    },
    SHOWPASS(state) {
      state.showPass = true
    },
    NOSHOWPASS(state) {
      state.showPass = false
    },
    SHOWDATUM(state) {
      state.showDatum = true
    },
    NOSHOWDATUM(state) {
      state.showDatum = false
    }
  },
  actions: {
  },
  modules: {
  }
})
