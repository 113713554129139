<template>
    <div class="common-title">
        <slot></slot>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.common-title {
    padding: 0 14px;
    font-weight: bold;
    font-size: 26px;
    color: #2E2D2D;
    border-left: 4px solid var(--theme-color);
}
</style>