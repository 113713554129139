import { Loading } from "element-ui";

export const servicesLoading = (node,str,lock,spinner,backgroundColor) => {
  return Loading.service({
    target: document.querySelector(node),//loading需要覆盖的DOM节点，默认为body
    text: str,//加载文案
    lock,//同v-loading的修饰符
    spinner,//加载图标
    backgroundColor,//背景色
    
  })
}