import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins/element'
import i18n from "./locale";


import './plugins/PaC'
import './assets/css/reset.css'
import './assets/css/flex.css'
import './assets/css/common.css'
import './plugins/axios'
import { servicesLoading } from './assets/js/loading'

// 全局方法挂载
Vue.prototype.$loading = servicesLoading

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
